import {Bootstrap} from 'Bootstrap/Bootstrap';

new (class extends Bootstrap {
    constructor() {
        super();
        window.targetEl = null;
        window.targetImage = null;
    }

    DOMReady() {

        $(document).on('click', '[data-media-attachment]', function() {
            // Setup targets
            let target = $(this).data('media-attachment');
            window.targetEl = $(target);

            let targetImage = $(this).data('media-image');
            window.targetImage = $(targetImage);

            // Popup
            let mediaPopup = window.open('/admin/medias/popup', 'mediaPopup', 'height=400,width=850');
            if (window.focus) {
                mediaPopup.focus();
            }
        });

        // register select Media funciton
        window.selectMedia = function(Media) {

            if (this.targetEl.length) {
                this.targetEl.val(Media.id);
            }

            if (this.targetImage.length) {
                this.targetImage.attr('src', Lib.Hash.get(Media, '_thumbs.small'));
            }
        };

    }
});
