import {Bootstrap} from 'Bootstrap/Bootstrap';


// All php loaded images go here

// General images
import '../img/frontend/logo-taxgoed.svg';
import '../img/frontend/logo-taxgoed-dark.svg';
import '../img/frontend/play-button.svg';
import '../img/frontend/building-stock.png';
import '../img/frontend/appartment.png';

// Swiper
import '../img/frontend/swiper-pointer-left.svg';
import '../img/frontend/swiper-pointer-right.svg';

// Icons
import '../img/frontend/icons/chat-bubble.svg';
import '../img/frontend/icons/plus.svg';
import '../img/frontend/icons/euro-house.svg';
import '../img/frontend/icons/rent.svg';
import '../img/frontend/icons/terugkoop.svg';
import '../img/frontend/icons/student.svg';
import '../img/frontend/icons/park.svg';
import '../img/frontend/icons/phone.svg';
import '../img/frontend/icons/facebook-circle.svg';
import '../img/frontend/icons/instagram.svg';
import '../img/frontend/icons/twitter.svg';
import '../img/frontend/icons/linkedin.svg';
import '../img/frontend/icons/phone-back.svg';
import '../img/frontend/icons/mail.svg';
import '../img/frontend/icons/close.svg';
import '../img/frontend/icons/place.svg';
import '../img/frontend/icons/send.svg';
import '../img/frontend/icons/chevron_down.svg';
import '../img/frontend/icons/chevron_up.svg';
import '../img/frontend/icons/email-green.svg';
import '../img/frontend/icons/email-dark-green.svg';
import '../img/frontend/icons/phone-green.svg';
import '../img/frontend/icons/phone-dark-green.svg';

// Buildings
import '../img/frontend/buildings/taxatie-woningcomplex.webp';
import '../img/frontend/buildings/taxatie-bedrijfspand.webp';
import '../img/frontend/buildings/taxatie-grond.webp';
import '../img/frontend/buildings/taxatie-maatschappelijk-vastgoed.webp';
import '../img/frontend/buildings/taxatie-zorgvastgoed.webp';

// Footer logo's
import '../img/frontend/logos/footer/nrvt.svg';
import '../img/frontend/logos/footer/nvm_business.svg';
import '../img/frontend/logos/footer/nvm.svg';
import '../img/frontend/logos/footer/nwwi.svg';
import '../img/frontend/logos/footer/tmi.svg';

// Expertises
import '../img/frontend/icons/verhuur.svg';
import '../img/frontend/icons/verkoop.svg';
import '../img/frontend/icons/vov.svg';
import '../img/frontend/icons/vve.svg';
import '../img/frontend/icons/support.svg';
import '../img/frontend/icons/bog.svg';

// Medewerkers
import '../img/frontend/medewerkers/diederik.png';
import '../img/frontend/medewerkers/alexander.png';
import '../img/frontend/medewerkers/pascal.png';
import '../img/frontend/medewerkers/marissa.png';
import '../img/frontend/medewerkers/anna.png';
import '../img/frontend/medewerkers/bas.png';
import '../img/frontend/medewerkers/bryan.png';
import '../img/frontend/medewerkers/xaveer.png';
import '../img/frontend/medewerkers/thom.png';
import '../img/frontend/medewerkers/sandra.png';

// Jobs
import '../img/frontend/jobs/jobs_sandra.png';
import '../img/frontend/jobs/jobs_thom.png';

// Corporations
import '../img/frontend/corporations/firmo.jpg';
import '../img/frontend/corporations/pandgarant.jpg';
import '../img/frontend/corporations/thorbecke.jpg';
import '../img/frontend/corporations/lekstedewonen.jpg';
import '../img/frontend/corporations/triada.jpg';

// Misc
import '../img/frontend/dummy.jpg';
import '../img/frontend/shape.svg';
import '../img/frontend/map-pointer-button.svg';
import '../img/frontend/arrow-down.svg';
import '../img/frontend/diederik_test.png';
import '../img/frontend/test_medewerker.png';
import '../img/frontend/bog-team.jpeg';

// Webp
import '../img/frontend/dit_zijn_wij.webp';
import '../img/frontend/taxatie-hoogeveen.webp';
import '../img/frontend/taxatie-woningcorporatie.webp';

class Default extends Bootstrap {

}

new Default();
