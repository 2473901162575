import {Bootstrap} from 'Bootstrap/Bootstrap';
import {Config} from '../../Lib/Config';
import loadGoogleMapsApi from 'load-google-maps-api';

// Icon
import markerIcon from '../../img/frontend/map-pointer.svg';

let pageConfig = Config.defaults({
    lat: 52.719500,
    lng: 6.451630
});

class Contact extends Bootstrap {

    DOMReady() {

        loadGoogleMapsApi({
            key: 'AIzaSyB3uHQxDT0vogwC2NOw4PTqr_jmyegHpo0'
        }).then((googleMaps) => {

            const map = new googleMaps.Map(document.getElementById('contactmap'), {
                center: {
                    lat: pageConfig.lat,
                    lng: pageConfig.lng
                },
                zoom: 11,
                disableDefaultUI: true
            });

            const icon = {
                url: markerIcon, // url
                scaledSize: new google.maps.Size(60, 60) // scaled size
            };

            new google.maps.Marker({
                position: {
                    lat: pageConfig.lat,
                    lng: pageConfig.lng
                },
                map: map,
                icon: icon
            });

        }).catch((error) => {
            console.error(error);
        });

    }

}

new Contact();
