// Get lodash
import {get, set, defaults, defaultsDeep} from 'lodash-es';

/**
 * Config
 *
 */
export let Config = (function () {

    let config = window.JsConfig;

    class Config {

        /**
         *
         * @param value
         * @param path
         */
        set(value, path) {
            set(config, this._getPath(path), value);
        }

        /**
         *
         * @param path
         * @returns {*}
         */
        get(path) {
            if (path === null) {
                return config;
            }

            return get(config, this._getPath(path));
        }

        /**
         *
         * @param default_options
         * @param deep
         * @param path
         * @returns {*}
         */
        defaults(default_options, deep, path) {
            let conf = this.get(path);

            if (!deep) {
                return defaults(conf, default_options);
            }

            return defaultsDeep(conf, default_options);
        }

        /**
         * Normalize the path to controller.action if non provided
         * @param path
         * @returns {*}
         * @private
         */
        _getPath(path) {
            return path ? path : `${this.get('App.controller')}.${this.get('App.action')}`;
        }
    }

    return new Config;
})();
