import {gsap} from 'gsap';

export default () => {

    let block_element = '.dit-zijn-wij-employees__block';

    gsap.set([block_element, block_element + ' .overlay'], {
        borderRadius: '30px 0 30px 30px'
    });

    gsap.set(block_element + ' .overlay', {
        background: 'linear-gradient(rgb(12 40 4 / 0) 50%, rgb(12 40 4), rgb(12, 40, 4))'
    });

    gsap.set('.dit-zijn-wij-employees__block__photo-container .round_shape', {
        background: 'rgb(242 244 241 / 1)',
        borderRadius: '50%',
        clipPath: '45%',
    });

    gsap.set('.dit-zijn-wij-employees__block__photo-container', {
        borderRadius: '50%',
        clipPath: '45%',
    });

    document.querySelectorAll('div.dit-zijn-wij-employees__block:not([data-type="job"])').forEach(element => {

        let tl = gsap.timeline({
            paused: true
        });

        // Block element (Parent)
        tl.to(element, {
            background: '#466144'
        }, 'contact');

        let contact = element.querySelector('[data-child="contact"]');

        // Slide contact in
        tl.to(contact, {
            xPercent: 100,
            opacity: 1
        }, 'contact');

        let photo_element = element.querySelector('[data-child="photo"]');

        // Photo container
        tl.to(photo_element, {
            clipPath: 'circle(100%)',
        }, 'contact');

        // Text container
        tl.to(element.querySelectorAll('[data-text-color="white"]'), {
            color: '#ffffff'
        }, 'contact');

        // Overlay element
        tl.to(element.querySelector('[data-child="overlay"]'), {
            height: '100%'
        }, 'contact');

        // Round shape element
        tl.to(photo_element.children[0], {
            background: 'rgb(255 255 255 / .12)',
        }, 'contact');

        // Set timeline duration
        tl.duration(0.5);

        // Add events
        element.addEventListener('mouseenter', () => tl.play());
        element.addEventListener('mouseleave', () => tl.reverse());
    });

}