import {Bootstrap} from '../../Bootstrap/Bootstrap';
import Animations from '../animations';

class Dit_zijn_wij extends Bootstrap {

    DOMReady() {

        Animations('dit_zijn_wij');

    }

}

new Dit_zijn_wij();