// Get DefaultModule
import {DefaultModule} from 'stein';
import 'legit-ripple/js/ripple';

import {Config} from '../Lib/Config';

// Swiper
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

// Bootstrap
import 'bootstrap';

// Rangy
import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';

// GSAP
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

// AOS
import AOS from 'aos';

import loadGoogleMapsApi from 'load-google-maps-api';
import markerIcon from '../img/frontend/map-pointer.svg';
import Animations from '../Entry/animations';
import {isBoolean} from 'lodash-es';

gsap.registerPlugin(ScrollTrigger);

let pageConfig = Config.defaults({
    typewriter: false,
    customer_swiper: false,
    news_swiper: false,
    lottie_animation: false,
    editing: false,
    page: false
});

/**
 *
 */
export class Bootstrap extends DefaultModule {
    constructor() {
        super();

        const self = this;

        // Subscribe DOMReady functions to the DOMReady event
        if (typeof this.DOMReady === 'function') {
            this.subscribe('DOMReady', this.DOMReady);
        }

        if (pageConfig.editing) {
            this.overrideMediumEditor();
        }

        gsap.set('div.transition', {
            translateY: '0%',
            clipPath: 'polygon(0 0%, 100% 0, 100% 100%, 0% 100%)'
        });

        // on DOMContentLoaded, publish DOMReady
        document.addEventListener('DOMContentLoaded', () => {
            this.publish('DOMReady');

            // noinspection JSUnresolvedFunction
            $('.ripple').ripple();

            $('[href="#"]').on('click', function(e) {
                e.preventDefault();
            });

            $(document).on('click', '[data-open-call-me-back-modal]', function(e) {
                e.preventDefault();
                $('body').addClass('modal-open');
                $('#call-me-back-modal').fadeIn(200);
            });

            $(document).on('click', '[data-request-taxatie]', function() {
                self.ajaxPromise('taxatie')
                .then((response) => {

                    self.handleForm(response, 'taxatie');

                })
            });

            $(document).on('click', '[data-request-application]', function() {

                self.ajaxPromise('application')
                .then((response) => {

                    self.handleForm(response, 'application');

                })
            });

            $(document).on('click', '[data-open-application-modal]', function() {

                let job_title = this.getAttribute('data-job-title');

                document.querySelector('#ApplicationJobTitle').value = job_title;
                document.querySelector('#application-modal span.sub_title').innerHTML = job_title;
            });

            $(document).on('click', '[data-waarde-van]', function() {

                let waarde_van = this.getAttribute('data-waarde-van');

                if (waarde_van) {
                    document.querySelector('#ContactWaardeVan').value = waarde_van;
                    document.querySelector('#taxatie-modal span.sub_title').innerHTML = 'voor ' + waarde_van.toLowerCase();
                }

            });

            if (pageConfig.customer_swiper) {
                this.customerSwiper();
            }

            if (pageConfig.news_swiper) {
                this.newsSwiper();
            }

            this.bindOffCanvasMenu();

            // Specific page actions
            switch (window.JsConfig.App.action) {
                case 'home':

                    // Run transition
                    self.pageTransition();

                    // Disable scroll
                    self.scrollDisable(false);

                    break;
                case 'waarde_van':

                    let hash = window.location.hash;

                    if (hash) {

                        setTimeout(function() {
                            $('html,body').animate({
                                scrollTop: $(hash).offset().top - ($(window).height() - $(hash).outerHeight(true)) / 2
                            }, 200);
                        }, 500);

                    }

                    break;
                case 'jobs':

                    document.querySelector('h1[data-slash]').insertAdjacentHTML(
                        'beforeend',
                        `<span class="jobs_count">${count_jobs}</span>`
                    );

                    break;
                case 'dit_zijn_wij':
                    break;
                case'contact':
                    break;
            }

            document.querySelectorAll('.menu--vacatures').forEach(element => {
                element.insertAdjacentHTML('beforeend', `<span class="jobs_count jobs_count--menu">${count_jobs}</span>`);
            });

            window.scrollTo({top: 0, left: 0, behavior: 'instant'});

            AOS.init();

        });

    }

    bindOffCanvasMenu() {
        $(document).on('click', '.menu_dot', function() {
            $(document.body).addClass('offcanvas-open');
        });

        $(document).on('click', '.close_offcanvas', function() {
            $(document.body).removeClass('offcanvas-open');
        });

        $(document).on('click', function(e) {
            if ($(e.target).closest('#offcanvas-menu').length === 0 && !$(e.target).is($('.menu_dot'))) {
                $(document.body).removeClass('offcanvas-open');
            }
        });
    }

    customerSwiper() {
        new Swiper('.customer-swiper-container', {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            slidesPerColumn: 1,
            spaceBetween: 40,
            initialSlide: Math.floor(($('.customer-swiper-container .swiper-slide').length / 2) / 2),
            navigation: {
                enabled: true,
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    navigation: {
                        enabled: true
                    }
                }
            }
        });

    }

    newsSwiper() {
        new Swiper('.news-swiper-container', {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            slidesPerColumn: 1,
            spaceBetween: 40,
            initialSlide: Math.floor(($('.news-swiper-container .swiper-slide').length / 2) / 2),
            navigation: {
                enabled: true,
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    centerInsufficientSlides: true,
                    navigation: {
                        enabled: true
                    }
                }
            }
        });

    }

    overrideMediumEditor() {

        rangy.init();

        let HighlighterButton = window.MediumEditor.extensions.button.extend({
            name: 'highlighter',
            tagNames: ['span'], // nodeName which indicates the button should be 'active' when isAlreadyApplied() is called
            contentDefault: '<i class="is-icon material-icons">brush</i>', // default innerHTML of the button
            aria: 'highlight', // used as both aria-label and title attributes
            action: 'highlight', // used as the data-action attribute of the button

            init: function() {
                window.MediumEditor.extensions.button.prototype.init.call(this);

                this.classApplier = rangy.createClassApplier('highlight-block', {
                    elementTagName: 'span',
                    normalize: true
                });

            },

            handleClick: function(event) {
                this.classApplier.toggleSelection();
                this.base.checkContentChanged();
            }
        });

        new window.MediumEditor($('[data-wysiwyg]').get(0), $.extend(true, {
                placeholder: {
                    text: '',
                    hideOnClick: true
                },
                toolbar: {
                    buttons: ['bold', 'italic', 'underline', 'strikethrough', 'h1', 'highlighter']
                },
                extensions: {
                    'highlighter': new HighlighterButton()
                },
                paste: {
                    forcePlainText: false,
                    cleanPastedHTML: true
                }
            }, $(this).data('wysiwyg'))
        );

    };

    contactMaps() {

        let pageConfig = Config.defaults({
            lat: 52.719500,
            lng: 6.451630
        });

        loadGoogleMapsApi({
            key: 'AIzaSyB3uHQxDT0vogwC2NOw4PTqr_jmyegHpo0'
        }).then((googleMaps) => {

            const map = new googleMaps.Map(document.getElementById('contactmap'), {
                center: {
                    lat: pageConfig.lat,
                    lng: pageConfig.lng
                },
                zoom: 11,
                disableDefaultUI: true
            });

            const icon = {
                url: markerIcon, // url
                scaledSize: new google.maps.Size(60, 60) // scaled size
            };

            new google.maps.Marker({
                position: {
                    lat: pageConfig.lat,
                    lng: pageConfig.lng
                },
                map: map,
                icon: icon
            });

        }).catch((error) => {
            console.error(error);
        });
    }

    delayPromise(n) {
        n = n || 2000;

        return new Promise(done => {
            setTimeout(() => {
                done();
            }, n);
        });
    }

    pageTransition() {

        let transition_div = 'div.transition';

        let tl = gsap.timeline();

        // Add scroll to timeline
        tl.add(() => {
            window.scrollTo(0, 0);

            if (document.querySelector('body').classList.contains('offcanvas-open')) {
                document.querySelector('.close_offcanvas').click();
            }

        });

        // Move transition overlay down (Hide)
        tl.to(transition_div, {
            duration: 1,
            translateY: '-100%',
            transformOrigin: 'top left',
            clipPath: 'polygon(0% 0%, 100% 0, 100% 100%, 0% 100%)',
            delay: 0.5,
            ease: 'power1.out',
            onComplete: () => {

                // Enable scrolling
                this.scrollDisable(false);

                gsap.set(transition_div, {
                   display: 'none',
                });

            }
        });

        gsap.set('.slash--home', {
            translateX: -30,
            ease: 'none'
        });

    }

    scrollDisable(enable = null) {

        if (!isBoolean(enable)) {
            return false;
        }

        let topElements = document.querySelectorAll('html, body');

        if (enable) {

            topElements.forEach(element => {
                element.classList.add('disable_scroll');
            });

        } else {

            topElements.forEach(element => {
                element.classList.remove('disable_scroll');
            });

        }

    }

    /**
     * Ajax request wrapped in a promise for posting forms
     * @param modalType
     * @returns {Promise<any>|boolean}
     */
    ajaxPromise(modalType) {

        let formData = new FormData(document.querySelector(`#${modalType}-modal form`));

        let formModel, formFields, postUrl;

        // Modal fields
        let modalFields = {
            'default': [
                'agree_terms',
                'initialen',
                'voornaam'
            ],
            'taxatie': [
                'bedrijfsnaam',
                'naam',
                'emailadres',
                'telefoonnummer',
                'bericht',
                'waarde_van'
            ],
            'application': [
                'naam',
                'linkedin',
                'emailadres',
                'telefoonnummer',
                'bericht',
                'job_title'
            ]
        };

        // Set correct form model
        switch (modalType) {
            case 'taxatie':
                formModel = 'Contact';
                break;

            case 'application':
                formModel = 'Application';
                break;
        }

        // Merge required fields
        formFields = [].concat(modalFields.default, modalFields[modalType]);

        // Set post url
        postUrl = `/request_${modalType}/`;

        // Return if no form fields
        if (!formFields) {
            return false;
        }

        // Setup formatted object
        let formatted_formData = {
            [formModel]: {}
        };

        // Format form data
        formFields.forEach(element => {
            formatted_formData[formModel][element] = formData.get(`data[${formModel}][${element}]`);
        });

        return new Promise((resolve, reject) => {
            $.ajax({
                url: postUrl,
                type: 'POST',
                data: formatted_formData,
                success: function(data) {
                    resolve(data);
                },
                error: function(error) {
                    reject(error);
                }
            });
        });
    }

    /**
     * Handle validation errors and success!
     * @param response
     * @param modalType
     */
    handleForm(response, modalType) {

        let data = JSON.parse(response);
        let modalTypeUpper = modalType[0].toUpperCase() + modalType.slice(1);

        document.querySelectorAll('.error-message').forEach(e => e.remove());

        if (!data.success) {

            Object.entries(data.validationErrors).forEach(error => {

                let [key, value] = error;
                let id = key[0].toUpperCase() + key.slice(1);

                if (modalType === 'taxatie') {
                    modalTypeUpper = 'Contact';
                }

                document.querySelector(`#${modalType}-modal #${modalTypeUpper}${id}`).insertAdjacentHTML(
                    'afterend',
                    `<div data-error-${key} class="error-message">${value}</div>`
                );

            });

        } else {

            const submitButton = document.querySelector(`#${modalType}-modal button[type="submit"]`);

            // Disable submit button
            submitButton.classList.add('disabled-button');
            submitButton.setAttribute('disabled', '');

            $('#thanks-message').show();
            $('.modal-form form').hide();
            $('.info-block').hide();

            const applicationModal = document.querySelector('#application-modal');
            applicationModal.addEventListener('hidden.bs.modal', event => {
                fetch(document.URL)
                .then(function(response) {
                    return response.text();
                })
                .then(function(html) {

                    let parser = new DOMParser();
                    let doc = parser.parseFromString(html, 'text/html');

                    applicationModal.innerHTML = doc.querySelector('#application-modal').innerHTML;
                });
            });

        }

    }

}
