import {Bootstrap} from 'Bootstrap/Bootstrap';

new (class extends Bootstrap {
    DOMReady() {
        $(document).on('click', '[data-media]', function() {
            console.log($(this).data('media'));
            window.opener.selectMedia($(this).data('media'));
            window.close();
        });
    }
});

