import Typewrite from './Animations/typewrite';
import TaxatieBlocks from './Animations/taxatie_blocks';
import Employees from './Animations/employees';

export default (page) => {

    switch (page) {
        case 'home':
            TaxatieBlocks();
            Typewrite();
            break;
        case 'dit_zijn_wij':
            Employees();
            break;
    }

}