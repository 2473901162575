
import Animations from '../animations';
import {Bootstrap} from '../../Bootstrap/Bootstrap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import gsap from 'gsap';

class Home extends Bootstrap {

    DOMReady() {

        this.customerSwiper();
        this.newsSwiper();

        let home_building = document.querySelector('.home-building');
        let home_header_building = document.querySelector('.header--home__image-container');

        // Kill all ScrollTrigger instances
        ScrollTrigger.getAll().forEach(t => t.kill(true));

        // Create new instance
        ScrollTrigger.create({
            animation: gsap.to(home_building, {
                yPercent: 100
            }),
            trigger: home_building,
            start: 'top+=48px top',
            end: 'bottom top',
            endTrigger: 'footer',
            scrub: true,
            force3D: true,
            normalizeScroll: true,
            //markers: {startColor: 'green', endColor: 'red', fontSize: '12px'}
        });

        // Create new instance
        ScrollTrigger.create({
            animation: gsap.to(home_header_building, {
                yPercent: -22
            }),
            trigger: home_header_building,
            start: 'top+=48px top',
            end: 'bottom-=5px bottom',
            endTrigger: '.home-taxatie',
            scrub: true,
            force3D: true,
            normalizeScroll: true,
            //markers: {startColor: 'green', endColor: 'red', fontSize: '16px'}
        });


        Animations('home');

        // Recalculate start and end positions
        ScrollTrigger.refresh(true);

    }

}

new Home();
