import {gsap} from 'gsap';

export default () => {

    document.querySelectorAll('div.home-taxatie__types__block').forEach(element => {

        let description = element.querySelector('[data-child="description"]');
        let description_height = description.scrollHeight;

        gsap.set(description, {
            height: 0
        });

        gsap.set(element.querySelector('[data-child="icon"]'), {
            rotate: 0
        });

        gsap.set(element, {
            marginBottom: 0
        });

        element.addEventListener('click', function() {

            let tl = gsap.timeline({
                paused: true
            });

            tl.addLabel('open');

            // Text container
            tl.to(element.querySelector('[data-child="icon"]'), {
                rotate: '180deg'
            }, 'open');

            tl.to(element, {
                marginBottom: '-2rem'
            }, 'open');

            // Open description
            tl.to(element.querySelector('[data-child="description"]'), {
                opacity: 1,
                height: description_height + 32
            }, 'open');

            let open_el = document.querySelector('.home-taxatie__types__block--open');

            // There is an open element
            if (open_el !== null) {

                let tl_open_el = gsap.timeline({
                    paused: true
                });

                tl_open_el.addLabel('close');

                tl_open_el.to(open_el.querySelector('[data-child="icon"]'), {
                    rotate: '0'
                }, 'close');

                tl_open_el.to(open_el, {
                    marginBottom: '0'
                }, 'close');

                // Open description
                tl_open_el.to(open_el.querySelector('[data-child="description"]'), {
                    opacity: 0,
                    height: 0
                }, 'close');

                // Diff element
                if (open_el.getAttribute('data-taxatie-type') !== this.getAttribute('data-taxatie-type')) {

                    open_el.classList.remove('home-taxatie__types__block--open');
                    tl_open_el.play();

                } else {

                    tl_open_el.restart();
                    this.classList.remove('home-taxatie__types__block--open');
                    tl_open_el.play();

                }

            }

            // Always add class and play animation
            // Previous state is already reverted and cleaned up
            if (open_el !== element) {
                this.classList.add('home-taxatie__types__block--open');
                tl.play();
            }

        });

    });

}