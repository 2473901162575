import gsap from 'gsap';
import {Draggable} from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

export default () => {

    let hasTouchScreen = false;
    let letters = [];

    let slash_tl = gsap.timeline({
        repeat: -1,
        repeatRefresh: true,
        yoyo: true,
        delay: 1
        //repeatDelay: 1.5,
    });

    let typewriteText = document.querySelector('.typewrite-text');
    let typewritePrepedTexts = new Map();

    function outputsize() {
        widthText = typewriteText.offsetWidth + 5;
    }

    new ResizeObserver(outputsize).observe(typewriteText);

    // Convert every letter of a word to an element
    [
        'woningcorporaties',
        'gemeenten',
        'bedrijven',
        'zorginstellingen',
        'beheerders',
        'VvE\'s'
    ].forEach(function(word, index) {

        typewritePrepedTexts.set(index, ((word) => {

            let seperatedWord = '';

            word.split('').forEach(function(element, index) {
                seperatedWord += `<div style="opacity: 0" class="char chars${index}">${element}</div>`;
            });

            return seperatedWord;

        })(word));

    });

    typewriteText.insertAdjacentHTML('beforeend', typewritePrepedTexts.get(0));
    let widthText = typewriteText.offsetWidth + 5;
    let count = 0;

    // --- Slash timeline ---
    // Move slash on X axis till width of text
    slash_tl.to('.slash--home', {
        x: () => widthText,
        ease: 'none',
        duration: 0.5,

        // Up count or reset if 5
        onComplete: () => {

            count++;

            if (count === 5) {
                count = 0;
            }
            slash_tl.repeatDelay(2);

        },

        // Set text visible
        onStart: () => {
            slash_tl.set(typewriteText, {
                opacity: 1
            });
            slash_tl.repeatDelay(1);
        },
        onReverseComplete: () => {
            typewriteText.replaceChildren(...htmlToElements(typewritePrepedTexts.get(count)));
            hideLetterOnHit();
            slash_tl.repeatDelay(1);
        }
    });

    // Init hit detection for letter elements
    hideLetterOnHit();

    // Add resize listener to reset if resized
    window.addEventListener('resize', function() {

        if (!hasTouchScreen) {
            clearTimeouts();
            resetTypewrite('resize');
        }

    });

    screen.orientation.addEventListener('change', () => {
        resetTypewrite();
    });

    /**
     *  Apply hit tests to letters
     */
    function hideLetterOnHit() {

        // Reset letters
        letters = [];

        gsap.ticker.remove(letterHitTest);

        gsap.ticker.add(letterHitTest);

    }

    /**
     * Check for each letter if there is a hit between the letter and the slash element
     * If there is collision fire opacity tween
     */
    function letterHitTest() {

        if (letters.length !== 0) {

            letters.forEach(function(letter) {
                if (Draggable.hitTest(letter, '.slash--home')) {

                    if (isPlayingBackwards(slash_tl, false)) {
                        gsap.to(letter, {
                            opacity: 0,
                            duration: 0.5
                        });
                    } else {
                        gsap.to(letter, {
                            opacity: 1,
                            duration: 0.5
                        });
                    }

                }

            });

        } else {

            // Create array from all letter elements
            letters = Array.from(document.querySelectorAll('.typewrite-text div')) ?? [];

        }

    }

    function isPlayingBackwards(animation, globally) {
        let reversed = animation.reversed(),
            totalTime = animation.totalTime(),
            cycleDuration;
        if (animation.repeat && animation.yoyo() && animation.repeat() && totalTime < animation.totalDuration()) {
            cycleDuration = animation.duration() + animation.repeatDelay();
            if (((totalTime / cycleDuration) | 0) & 1) {
                reversed = !reversed;
            }
        }
        if (globally && animation.timeline && isPlayingBackwards(animation.timeline, globally)) {
            reversed = !reversed;
        }
        return reversed;
    }

    /**
     * Convert a string or node to an actual element
     *
     * @param html
     * @returns {NodeListOf<ChildNode>}
     */
    function htmlToElements(html) {
        let template = document.createElement('template');
        template.innerHTML = html;
        return template.content.childNodes;
    }

    /**
     * Reset Typewrite animation
     */
    function resetTypewrite(event) {

        slash_tl.pause();

        gsap.set('.slash--home', {
            x: () => -30
        });

        // Replace all childs with first element
        typewriteText.replaceChildren(...htmlToElements(typewritePrepedTexts.get(count)));

        let delay = 200;

        if (event === 'resize') {
            delay = 1250;
        }

        setTimeout(() => {

            // Reset timeline to 0
            slash_tl.play(0);

            // Invalidate any values within timeline to start fresh
            slash_tl.invalidate();

            // Reapply hit detection to letters
            hideLetterOnHit();

        }, delay);

    }

    /**
     * Detect if tab is out of focus
     *
     * @type {function(*): boolean}
     */
    let tabFocus = (() => {
        let stateKey, eventKey, keys = {
            hidden: 'visibilitychange',
            webkitHidden: 'webkitvisibilitychange',
            mozHidden: 'mozvisibilitychange',
            msHidden: 'msvisibilitychange'
        };
        for (stateKey in keys) {
            if (stateKey in document) {
                eventKey = keys[stateKey];
                break;
            }
        }

        return function(c) {
            if (c) document.addEventListener(eventKey, c);
            return !document[stateKey];
        };

    })();

    // Reset typewrite animation
    // tabFocus(() => {
    //     resetTypewrite();
    // });

    if ('maxTouchPoints' in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
        const mQ = matchMedia?.('(pointer:coarse)');
        if (mQ?.media === '(pointer:coarse)') {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            const UA = navigator.userAgent;
            hasTouchScreen =
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
    }

    function clearTimeouts(log = false) {

        // Clear Timeouts on initiation
        for (let i = setTimeout(() => {
        }, 0); i > 0; i--) {

            if (log) {
                console.log(i);
            }

            clearTimeout(i);
        }

    }

}